<div class="social-media-container">
  <a
    *ngIf="social_media_links?.instagram_link"
    [href]="social_media_links?.instagram_link"
    target="_blank"
  >
    <div class="link-container">
      <img src="/assets/icons/social_media/instagram.png" alt="Instagram" />
      <p>Instagram</p>
    </div>
  </a>
  <a
    *ngIf="social_media_links?.facebook_link"
    target="_blank"
    [href]="social_media_links?.facebook_link"
  >
    <div class="link-container">
      <img src="/assets/icons/social_media/facebook.png" alt="Facebook" />
      <p>Facebook</p>
    </div>
  </a>
  <a
    *ngIf="social_media_links?.tik_tok_link"
    target="_blank"
    [href]="social_media_links?.tik_tok_link"
  >
    <div class="link-container">
      <img src="/assets/icons/social_media/tik_tok.png" alt="Tik Tok" />
      <p>Tik Tok</p>
    </div>
  </a>
</div>
