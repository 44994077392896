import {Component, Inject, OnInit} from '@angular/core';
import { select, Store } from '@ngrx/store'
import { selectBusinessDetails, selectSiteInitials } from '../../../common/components/loader/reducers'
import { environment } from '../../../../environments/environment'
import { SiteRoutes } from '../../../routes.const'
import { mergeMap, take, takeUntil, takeWhile } from 'rxjs/operators'
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog'
import { MobileDetectorService } from '../../../common/services/mobile-detector/mobile-detector.service'
import { Router } from '@angular/router'
import { OrderService } from '../../modules/order/order.service'
import { interval } from 'rxjs'
import { OrderActions } from '../../modules/order/actions'
import { Subscriber } from '../../../common/classes/subscriber/subscriber'
import {InfoModalComponent} from "../../../common/components/info-modal/info-modal.component";
import {
  AddProductModalComponent
} from "../../../common/components/products/products/add-product-modal/add-product-modal.component";

@Component({
  selector: 'bi-select-payment-type-modal',
  templateUrl: './select-payment-type-modal.component.html',
  styleUrls: ['./select-payment-type-modal.component.less']
})
export class SelectPaymentTypeModalComponent extends Subscriber implements OnInit {

  staticFilesPath = ''
  paymentType?: string

  constructor(
    private store: Store,
    private mobileDetectorService: MobileDetectorService,
    public router: Router, private orderService: OrderService,
    private infoDialogRef: MatDialogRef<InfoModalComponent>,
    private dialogRef: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {
    super()
    store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
    })
  }

  ngOnInit(): void {
  }

  setPaymentType(paymentType: string) {
    this.paymentType = paymentType
    if (this.paymentType === 'cash') {
      this.onCashPayment()
    } else if (this.paymentType === 'visa') {
      this.onCreditPaymentForCG()
    }
    this.dialogRef.closeAll()
  }

  // doPayment() {
  //   if (this.paymentType === 'cash') {
  //     this.onCashPayment()
  //   } else if (this.paymentType === 'visa') {
  //     this.onCreditPaymentForCG()
  //   }
  //   this.dialogRef.closeAll()
  // }

  onCashPayment() {
    this.orderService.setPayment('cash')
    this.router.navigate([SiteRoutes.ConfirmCash])
  }

  onCreditPaymentForCG() {
    this.orderService.setPayment('visa')
    this.orderService.preCreditPaymentForCG().subscribe(
      ({paylink}) => {
        const order = this.orderService.getOrder()
        let total = order.total
        if (order.deliveryOption === 'delivery') {
          this.store.pipe(take(1), select(selectBusinessDetails)).subscribe((business) => {
            if (business?.business) {
              total += business.business.delivery_cost
            }
          })
        }

        this.openPaymentWindow(paylink)
      },
      () => {
        // this.openPaymentWindow("paylink")

        alert('אירעה שגיאה, נא לנסות שנית')
      },
    )
  }

  private openPaymentWindow(payLink: string) {
    // const win = window.open(payLink, '_blank', 'top=20,left=500,width=500,height=650')


    this.dialogRef.open(InfoModalComponent, {
      panelClass: 'zcredit',
      disableClose: true,
      data: {
        iframe: payLink,
        cancelBtnCallback: () => {
          this.infoDialogRef.close()
        },
        cancelBtnText: 'סגור'
      }
    })

    // check if payment is done every 5 seconds
    interval(5000)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        mergeMap(() => this.orderService.isOrderCreditPaid()),
        takeWhile((response: any) => !!response.error, true),
      )
      .subscribe((response: any) => {
        if (!response.error && response.order_id) {
          localStorage.setItem('order_id', response.order_id)
          this.dialogRef.closeAll()
          this.store.dispatch(OrderActions.onCompletedOrder())
          this.router.navigate([SiteRoutes.Tracker])
        }
      })
  }

  protected readonly SiteRoutes = SiteRoutes;
}
