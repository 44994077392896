import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import {
  IBusinessResponse,
  SocialMediaLinks,
} from 'src/app/common/services/business/business.model'
import { selectBusinessDetails } from '../../loader/reducers'

@Component({
  selector: 'bi-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.less'],
})
export class SocialMediaComponent implements OnInit {
  business$: Observable<IBusinessResponse | null> = this.store.pipe(select(selectBusinessDetails))
  social_media_links?: SocialMediaLinks
  is_social_media_links_exist?: boolean

  constructor(private store: Store) {}

  ngOnInit() {
    this.business$.subscribe((business) => {
      this.social_media_links = business?.business.business_information.social_media_links
      this.is_social_media_links_exist = Boolean(
        this.social_media_links?.facebook_link ||
          this.social_media_links?.instagram_link ||
          this.social_media_links?.tik_tok_link,
      )
    })
  }
}
