import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouterModule } from '@angular/router'
import { PushModule } from '@ngrx/component'
import { HeaderModule } from '../header/header.module'
// components
import { SocialMediaComponent } from './social-media/social-media.component'

const declarations = [SocialMediaComponent]

@NgModule({
  imports: [BrowserModule, PushModule, RouterModule, HeaderModule],
  declarations: declarations,
  exports: declarations,
})
export class LayoutModule {}
