<ng-container *ngIf="business$|ngrxPush as business">
  <div
    class="page-container"
    [class.inDealPage]="isDealPage"
    style="height: 100%"
    *ngIf="siteData$ | ngrxPush as siteData"
  >
    <div class="page-title" *ngIf="siteData.theme === 'light'">ההזמנה שלי</div>
    <div class="cart-container" *ngIf="$cart | ngrxPush as cart">
      <div class="cart" *ngIf="cart.length">
        <div class="cart-body">
          <div class="product-body" *ngFor="let product of cart; let index = index">
            <ng-container [ngSwitch]="product.type_name">
              <ng-container *ngSwitchCase="productType.deal">
                <bi-deal-in-cart
                  [deal]="product"
                  (onRemoveTopping)="onRemoveToppingInDeal($event, index)"
                ></bi-deal-in-cart>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <bi-regular-product
                  [product]="product"
                  [index]="index"
                  (onRemoveTopping)="onRemoveTopping($event, index)"
                ></bi-regular-product>
              </ng-container>
            </ng-container>
          </div>
          <ng-container class="total-container" *ngIf="business$ | ngrxPush as business">
            <div
              class="total"
              *ngIf="deliveryOption === 'delivery' && business.business.delivery_cost !=0"
            >
              <div class="delivery-price">
                <div class="delivery-title">
                  <img [src]="staticFilesPath + 'icons/moto.svg'" class="responsive" />
                  משלוח
                </div>
                <div class="price shekel">{{business.business.delivery_cost}}</div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="cart-total" *ngIf="business$ | ngrxPush as business">
          <span class="error-text" *ngIf="showMinOrderError">
            <span> הזמנה מינימאלית באתר הינה </span>
            <span class="shekel"> {{minDeliveryPrice}} </span>
          </span>

          <div class="total-price-container">
            <div class="total-price">
              <span class="price shekel" *ngIf="$total | ngrxPush as total"> {{total}}&nbsp; </span>
              <span class="dots"></span>
              <span class="description"> מחיר סופי</span>
            </div>

            <div class="total-price" *ngIf="$total | ngrxPush as total">
              <span class="price shekel">
                {{deliveryOption==='delivery' ? total + business.business.delivery_cost :
                total}}&nbsp;
              </span>
              <span class="dots"></span>
              <span class="description">ההזמנה שלי</span>
            </div>
          </div>

          <div class="payment-actions-container" *ngIf="siteData$ | ngrxPush as siteData">
            <span
              class="add-coupon-n"
              *ngIf="siteData.theme === 'light'&&  !$cartCoupon"
              (click)="showAddCouponModal()"
              >מימוש קופון</span
            >
            <div class="actions-container">
              <div class="clear-cart" (click)="onClearCart()">
                <span>רוקן עגלה</span>
                <img
                  [src]="staticFilesPath + 'icons/trash.png'"
                  class="responsive"
                  alt="רוקן עגלה"
                />
              </div>
              <button
                class="bi-btn full"
                (click)="onGoToPayment()"
                [disabled]="!business.business.is_opened || ($cartCoupon && cart.length == 1)"
              >
                לתשלום
              </button>
              <p style="color: red" *ngIf="$cartCoupon && cart.length == 1 ">
                עלייך להוסיף מוצרים נוספים לעגלה
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="empty-cart" *ngIf="!cart.length">
        <span> עגלה ריקה </span>
      </div>
    </div>
  </div>
</ng-container>
