import { Component, OnInit } from '@angular/core'
import {
  GuardsCheckEnd,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router'
import { Store } from '@ngrx/store'
import { environment } from './../environments/environment'
import { CartActions } from './common/components/cart/actions'
import { SiteLoaderActions } from './common/components/loader/actions'
import { ProductsActions } from './common/components/products/actions'
import { ISiteInitials } from './common/initial-data/initial-data.model'
import { InitSiteService } from './common/services/init-site/init-site.service'
import { SiteRoutes } from './routes.const'
import { DeliveryOption } from './site/modules/order/order.model'
import { OrderService } from './site/modules/order/order.service'

@Component({
  selector: 'site-root',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.less'],
})
export class SiteComponent implements OnInit {
  staticFilesPath = ''
  isPageLoading = false
  loadPre = false
  site?: ISiteInitials
  favIcon: any = document.querySelector('#favIcon')
  showFooter = true
  isHomePage = false

  constructor(
    private store: Store,
    private initSiteService: InitSiteService,
    private router: Router,
    private orderService: OrderService,
  ) {
    this.router.events.subscribe((ev) => {
      // update cart state
      if (ev instanceof NavigationStart) {
        this.loadPre = true
        this.isPageLoading = true

        if (ev.url === '/') this.isHomePage = true
        else this.isHomePage = false
      } else if (ev instanceof GuardsCheckEnd) {
        if (
          ev.shouldActivate &&
          ev.urlAfterRedirects != `/${SiteRoutes.Pizza}/${SiteRoutes.PizzaSelect}` &&
          ev.urlAfterRedirects != `/${SiteRoutes.AddPizza}` &&
          ev.urlAfterRedirects != `/${SiteRoutes.AddDeal}`
        ) {
          this.store.dispatch(CartActions.onUpdateCartTotal())
          this.store.dispatch(ProductsActions.onAddProduct({ product: null }))
          this.store.dispatch(ProductsActions.onAddDeal({ deal: null }))
        }
      } else if (
        ev instanceof NavigationEnd ||
        ev instanceof NavigationCancel ||
        ev instanceof NavigationError
      ) {
        window.scrollTo(0, 0)
        this.loadPre = false
        setTimeout(() => {
          this.isPageLoading = false
        }, 500)
      }
    })
  }

  ngOnInit() {
    this.store.dispatch(SiteLoaderActions.onSiteInitialLoading())
    this.initSite()
    this.initDeliveryOptionObserver()
    this.setIcon()
  }

  setIcon() {
    this.favIcon.href = this.staticFilesPath + 'small_logo.png'
  }
  initSite() {
    this.site = this.initSiteService.selectSiteInitials()
    this.staticFilesPath = `${environment.staticFiles + this.site.name}/`
  }

  initDeliveryOptionObserver() {
    this.orderService.initDeliveryOptionObserver()
    this.orderService.selectedDeliveryOptionSubject$
      .asObservable()
      .subscribe((opt: DeliveryOption | undefined) => {
        if (!opt && !this.isStandaloneRoute()) {
          this.router.navigate([SiteRoutes.Home])
        }
      })
  }

  private isStandaloneRoute(): boolean {
    const routes = [
      SiteRoutes.Tracker,
      SiteRoutes.AndroidApp,
      SiteRoutes.IOSApp,
      SiteRoutes.UnsubscribeAds,
      SiteRoutes.About,
      SiteRoutes.ContactPizziria,
      SiteRoutes.ContactBringit,
      SiteRoutes.SuggestImporve,
      SiteRoutes.Terms,
    ]
    return routes.some((route) => location.href.includes(route))
  }

  showBg() {
    return this.router.url !== SiteRoutes.Home
  }

  getDesktopBgUrl() {
    if (this.router.url === SiteRoutes.Home) {
      return 'url(' + this.staticFilesPath + 'desktop_bg.jpg)'
    }
    return 'url(' + this.staticFilesPath + 'default_bg.jpg)'
  }

  getMobileBgUrl() {
    if (this.router.url === SiteRoutes.Home) {
      return 'url(' + this.staticFilesPath + 'mobile_bg.jpg)'
    }
    return 'url(' + this.staticFilesPath + 'dmobile_bg.jpg)'
  }
}
