import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { Observable, combineLatest } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { environment } from '../../../../../environments/environment'
import { AddCouponModalComponent } from '../../../../site/cart-page/add-coupon-modal/add-coupon-modal.component'
import { IRemoveCartTopping, ProductType } from '../../../../site/modules/product/product.model'
import { ISiteInitials } from '../../../initial-data/initial-data.model'
import { IBusinessResponse } from '../../../services/business/business.model'
import { InfoModalComponent } from '../../info-modal/info-modal.component'
import { selectSiteInitials } from '../../loader/reducers'
import { CartActions } from '../actions'
import { selectCartProducts } from '../reducers'
import { SiteRoutes } from './../../../../routes.const'
import { OrderService } from './../../../../site/modules/order/order.service'
import { MobileDetectorService } from './../../../services/mobile-detector/mobile-detector.service'
import { selectBusinessDetails } from './../../loader/reducers/index'
import {
  selectDealItemToAdd,
  selectDealPreAdd,
  selectProductPreAdd,
} from './../../products/reducers/index'

@Component({
  selector: 'bi-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.less'],
})
export class CartComponent implements OnInit {
  business$: Observable<IBusinessResponse | null> = this.store.pipe(select(selectBusinessDetails))
  siteData: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))
  @Input()
  isFinal = false

  @Input()
  showMinOrderError = false

  @Input() isDealPage = false

  staticFilesPath = ''
  isMobile: boolean
  productType = ProductType
  isDelivery = false
  deliveryCost = 0
  minDeliveryPrice = 0
  deliveryOption = this.orderService.getDeliveryOption()
  $cartProducts = this.store.pipe(select(selectCartProducts))
  $productToAdd = this.store.pipe(select(selectProductPreAdd))
  $dealToAdd = this.store.pipe(select(selectDealPreAdd))
  $itemDeal = this.store.pipe(select(selectDealItemToAdd))
  $cartCoupon = this.orderService.cartHasCoupon()

  $cart = combineLatest([
    this.$cartProducts,
    this.$productToAdd,
    this.$dealToAdd,
    this.$itemDeal,
  ]).pipe(
    map(([cart, product, deal, itemIndex]) => {
      let toCart = JSON.parse(JSON.stringify(cart))
      if (deal && itemIndex != null) {
        let toDeal = JSON.parse(JSON.stringify(deal))
        if (product) {
          if (toDeal.items[itemIndex]?.products?.length) {
            toDeal.items[itemIndex].products[0] = product
          } else {
            toDeal.items[toDeal.items.length - 1]?.products?.push(product)
          }
        }
        toCart.push(toDeal)
      } else if (product) {
        toCart.push(product)
      }
      return toCart
    }),
  )

  $total: Observable<number> = this.$cart.pipe(
    map((cart) => {
      this.$cartCoupon = this.orderService.cartHasCoupon()
      return this.orderService.calculateTotal(cart)
    }),
  )
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))

  constructor(
    private store: Store,
    private mobileDetectorService: MobileDetectorService,
    private router: Router,
    private orderService: OrderService,
    private dialogRef: MatDialog,
  ) {
    this.isMobile = this.mobileDetectorService.isMobile()
    store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
    })
  }

  ngOnInit(): void {
    this.setDeliveryCost()
  }

  onClearCart() {
    this.store.dispatch(CartActions.onClearCart())
  }

  onGoToPayment() {
    if (this.orderService.hasErrorMinOrder()) {
      this.showMinOrderError = true
      this.dialogRef.open(InfoModalComponent, {
        panelClass: 'info-modal',
        data: { text: '  הזמנה מינימאלית באתר הינה  ' + this.minDeliveryPrice, okBtnText: 'אישור' },
      })
      return
    }

    this.showMinOrderError = false
    if (this.isFinal) {
      this.router.navigate([SiteRoutes.Notes])
    } else {
      this.router.navigate([SiteRoutes.Cart])
    }
  }

  onRemoveTopping(details: Partial<IRemoveCartTopping>, productIndex: number) {
    const data: IRemoveCartTopping = {
      ...details,
      productIndex,
    } as IRemoveCartTopping

    this.store.dispatch(CartActions.onRemoveTopping(data))
  }

  onRemoveToppingInDeal(details: IRemoveCartTopping, dealIndex: number) {
    const data: IRemoveCartTopping = {
      ...details,
      dealIndex,
    }

    this.store.dispatch(CartActions.onRemoveTopping(data))
  }

  private setDeliveryCost() {
    const deliveryOption = this.orderService.getDeliveryOption()
    if (deliveryOption === 'delivery') {
      this.store.pipe(take(1), select(selectBusinessDetails)).subscribe((business) => {
        if (business!.business) {
          this.isDelivery = true
          this.deliveryCost = business!.business.delivery_cost
          this.minDeliveryPrice = business!.business.min_delivery_price
        }
      })
    }
  }

  showAddCouponModal() {
    if (!this.$cartCoupon) {
      this.dialogRef.open(AddCouponModalComponent, {
        panelClass: 'coupon-modal',
      })
    }
    return
  }
}
