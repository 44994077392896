<main
  id="main"
  [style.--mobileBgUrl]="getMobileBgUrl()"
  [style.--desktopBgUrl]="getDesktopBgUrl()"
  class="italiya"
  [ngClass]="[site?.name, isHomePage ? 'home' : '']"
>
  <div id="main-content">
    <div class="main-header">
      <bi-header></bi-header>
    </div>
    <div class="main-ui-view">
      <router-outlet></router-outlet>
      <div id="main-bg" *ngIf="showBg()"></div>
      <bi-social-media></bi-social-media>
    </div>
    <div class="main-footer">
      <bi-footer *ngIf="showFooter"></bi-footer>
    </div>
  </div>
</main>
<bi-loader></bi-loader>

<div class="page-loader" *ngIf="isPageLoading">
  <div class="loader" [ngClass]="{'centred' : loadPre}">
    <svg width="150" height="150" xmlns="http://www.w3.org/2000/svg" version="1.1">
      <g>
        <title>Layer 1</title>
        <g stroke="null" id="sparkles">
          <path
            stroke="null"
            id="svg_1"
            d="m83.51823,8.04051l0.31611,0c0.79336,0 1.42557,-0.47872 1.58672,-1.27452l0.95451,-5.40887c0.16115,-0.7958 -0.47725,-1.7533 -1.27061,-1.90864c-0.79336,-0.16164 -1.74793,0.47872 -1.90279,1.27452l-0.95451,5.40887c-0.16115,0.95123 0.47725,1.74709 1.27061,1.90864l-0.00003,0z"
          />
          <path
            stroke="null"
            id="svg_2"
            d="m120.3285,11.54074c-0.63221,-0.63415 -1.58672,-0.63415 -2.21887,0l-0.47725,0.47872l-0.47725,-0.47872c-0.63221,-0.63415 -1.58672,-0.63415 -2.21887,0c-0.63221,0.63415 -0.63221,1.59159 0,2.22569l0.47725,0.47872l-0.47725,0.47872c-0.63221,0.63415 -0.63221,1.59159 0,2.22569c0.31611,0.31708 0.79336,0.47872 1.10946,0.47872s0.79336,-0.16164 1.10946,-0.47872l0.47725,-0.47872l0.47725,0.47872c0.31611,0.31708 0.79336,0.47872 1.10946,0.47872s0.79336,-0.16164 1.10946,-0.47872c0.63221,-0.63415 0.63221,-1.59159 0,-2.22569l-0.47725,-0.47872l0.47725,-0.47872c0.63221,-0.63415 0.63221,-1.59159 0,-2.22569l-0.00013,0z"
          />
          <path
            stroke="null"
            id="svg_3"
            d="m117.31691,37.32297l-1.90279,1.90864c-0.63221,0.63415 -0.63221,1.59159 0,2.22569c0.31611,0.31708 0.79336,0.47872 1.10946,0.47872c0.31611,0 0.79336,-0.16164 1.10946,-0.47872l1.90279,-1.90864c0.63221,-0.63415 0.63221,-1.59159 0,-2.22569c-0.63221,-0.63415 -1.58672,-0.63415 -2.21887,0l-0.00006,0z"
          />
          <path
            stroke="null"
            id="svg_4"
            d="m137.7824,30.63987c0,2.5491 -3.80558,2.5491 -3.80558,0s3.80558,-2.5491 3.80558,0"
          />
          <path
            stroke="null"
            id="svg_5"
            d="m147.93422,54.99285l0.79336,-0.7958c0.63221,-0.63415 0.63221,-1.59159 0,-2.22569c-0.63221,-0.63415 -1.58672,-0.63415 -2.21887,0l-0.79336,0.7958l-0.79336,-0.80202c-0.63221,-0.63415 -1.58672,-0.63415 -2.21887,0c-0.63221,0.63415 -0.63221,1.59159 0,2.22569l0.79336,0.7958l-0.79336,0.7958c-0.63221,0.63415 -0.63221,1.59159 0,2.22569c0.31611,0.31708 0.79336,0.47872 1.10946,0.47872s0.79336,-0.16164 1.10946,-0.47872l0.79336,-0.7958l0.79336,0.7958c0.31611,0.31708 0.79336,0.47872 1.10946,0.47872s0.79336,-0.16164 1.10946,-0.47872c0.63221,-0.63415 0.63221,-1.59159 0,-2.22569l-0.79349,-0.78957z"
          />
          <path
            stroke="null"
            id="svg_6"
            d="m126.01212,59.08961c2.09494,1.43616 -0.04958,4.59445 -2.14461,3.15836c-2.10113,-1.42995 0.04338,-4.58825 2.14461,-3.15836"
          />
          <path
            stroke="null"
            id="svg_7"
            d="m114.70676,73.90576c1.27061,2.20086 -2.02671,4.1157 -3.29736,1.90864c-1.27061,-2.20706 2.02671,-4.1157 3.29736,-1.90864"
          />
          <path
            stroke="null"
            id="svg_8"
            d="m132.54464,85.38752c0.79336,0 1.42557,-0.47872 1.58672,-1.27452c0.16115,-0.7958 -0.47725,-1.7533 -1.27061,-1.90864l-5.3923,-0.95744c-0.79336,-0.16164 -1.74793,0.47872 -1.90279,1.27452c-0.16115,0.7958 0.47725,1.7533 1.27061,1.90864l5.3923,0.95744l0.31611,0l-0.00003,0z"
          />
          <path
            stroke="null"
            id="svg_9"
            d="m142.48702,98.28262c2.52256,0.27977 2.10732,4.07225 -0.42146,3.79245c-2.52256,-0.27977 -2.10113,-4.07225 0.42146,-3.79245"
          />
          <path
            stroke="null"
            id="svg_10"
            d="m117.94842,105.28245l-1.10946,0l0,-1.11287c0,-0.95744 -0.63221,-1.59159 -1.58672,-1.59159c-0.95451,0 -1.58672,0.63415 -1.58672,1.59159l0,1.11287l-1.10946,0c-0.95451,0 -1.58672,0.63415 -1.58672,1.59159c0,0.95744 0.63221,1.59159 1.58672,1.59159l1.10946,0l0,0.95744c0,0.95744 0.63221,1.59159 1.58672,1.59159c0.95451,0 1.58672,-0.63415 1.58672,-1.59159l0,-1.11287l1.10946,0c0.95451,0 1.58672,-0.63415 1.58672,-1.59159c0,-0.95744 -0.79336,-1.43616 -1.58672,-1.43616z"
          />
          <path
            stroke="null"
            id="svg_11"
            d="m131.59737,121.04401c-2.21887,0 -3.96679,1.7533 -3.96679,3.97899c0,2.22569 1.74793,3.97899 3.96679,3.97899c2.21887,0 3.96679,-1.7533 3.96679,-3.97899c0,-2.07035 -1.74793,-3.97899 -3.96679,-3.97899zm0,4.77478c-0.47725,0 -0.79336,-0.31708 -0.79336,-0.7958s0.31611,-0.7958 0.79336,-0.7958c0.47725,0 0.79336,0.31708 0.79336,0.7958s-0.32229,0.7958 -0.79336,0.7958z"
          />
          <path
            stroke="null"
            id="svg_12"
            d="m112.16008,132.20267c2.52891,0.27977 2.10732,4.07225 -0.41528,3.79245c-2.52256,-0.27977 -2.10732,-4.07225 0.41528,-3.79245"
          />
          <path
            stroke="null"
            id="svg_13"
            d="m89.3875,136.00181c-2.85736,0 -5.23744,2.38739 -5.23744,5.25353s2.38008,5.25353 5.23744,5.25353s5.23744,-2.38739 5.23744,-5.25353s-2.38008,-5.25353 -5.23744,-5.25353zm0,7.47922c-1.10946,0 -2.064,-0.95744 -2.064,-2.07035c0,-1.11287 0.95451,-2.07035 2.064,-2.07035s2.064,0.95744 2.064,2.07035c0,1.11908 -0.95451,2.07035 -2.064,2.07035z"
          />
          <path
            stroke="null"
            id="svg_14"
            d="m64.50142,144.92428c2.52891,0.27977 2.10732,4.07846 -0.41528,3.79866c-2.52256,-0.27977 -2.10732,-4.07846 0.41528,-3.79866"
          />
          <path
            stroke="null"
            id="svg_15"
            d="m55.43491,136.16415c0,2.54289 -3.81177,2.54289 -3.81177,0c0,-2.5491 3.81177,-2.5491 3.81177,0"
          />
          <path
            stroke="null"
            id="svg_16"
            d="m34.48389,141.418c0,2.54289 -3.80558,2.54289 -3.80558,0c0,-2.5491 3.80558,-2.5491 3.80558,0"
          />
          <path
            stroke="null"
            id="svg_17"
            d="m35.55016,115.61667c2.10113,1.42995 -0.04338,4.58825 -2.13842,3.15836c-2.10113,-1.43616 0.04338,-4.59445 2.13842,-3.15836"
          />
          <path
            stroke="null"
            id="svg_18"
            d="m13.70264,117.06502c-0.63221,-0.63415 -1.58672,-0.63415 -2.21887,0l-0.47725,0.47872l-0.47725,-0.47872c-0.63221,-0.63415 -1.58672,-0.63415 -2.21887,0c-0.63221,0.63415 -0.63221,1.59159 0,2.22569l0.47725,0.47872l-0.47725,0.47872c-0.63221,0.63415 -0.63221,1.59159 0,2.22569c0.31611,0.31708 0.79336,0.47872 1.10946,0.47872c0.31611,0 0.79336,-0.16164 1.10946,-0.47872l0.47725,-0.47872l0.47725,0.47872c0.31611,0.31708 0.79336,0.47872 1.10946,0.47872c0.31611,0 0.79336,-0.16164 1.10946,-0.47872c0.63221,-0.63415 0.63221,-1.59159 0,-2.22569l-0.47725,-0.47872l0.47725,-0.47872c0.63221,-0.63415 0.63221,-1.59159 0,-2.22569l-0.00013,0z"
          />
          <path
            stroke="null"
            id="svg_19"
            d="m26.19805,100.29121c2.09494,1.43616 -0.04958,4.59445 -2.14461,3.15836c-2.10113,-1.43616 0.04338,-4.58825 2.14461,-3.15836"
          />
          <path
            stroke="null"
            id="svg_20"
            d="m8.74383,98.53091c2.10113,1.42995 -0.04338,4.58825 -2.14461,3.15836c-2.09494,-1.43616 0.04958,-4.59445 2.14461,-3.15836"
          />
          <path
            stroke="null"
            id="svg_21"
            d="m30.51709,82.20434c0.95451,0 1.58672,-0.63415 1.58672,-1.59159c0,-0.95744 -0.63221,-1.59159 -1.58672,-1.59159l-1.10946,0l0,-1.11287c0,-0.95744 -0.63221,-1.59159 -1.58672,-1.59159c-0.95451,0 -1.58672,0.63415 -1.58672,1.59159l0,1.11287l-1.10946,0c-0.95451,0 -1.58672,0.63415 -1.58672,1.59159c0,0.95744 0.63221,1.59159 1.58672,1.59159l1.10946,0l0,1.11287c0,0.95744 0.63221,1.59159 1.58672,1.59159c0.95451,0 1.58672,-0.63415 1.58672,-1.59159l0,-1.11287l1.10946,0z"
          />
          <path
            stroke="null"
            id="svg_22"
            d="m7.83274,72.33804c-0.47725,-0.7958 -1.42557,-0.95744 -2.21887,-0.31708l-4.44408,3.18319c-0.79336,0.47872 -0.95451,1.42995 -0.31611,2.22569c0.31611,0.47872 0.79336,0.63415 1.27061,0.63415c0.31611,0 0.63221,-0.16164 0.95451,-0.31708l4.44408,-3.18319c0.78097,-0.47251 0.94211,-1.58537 0.3099,-2.22569l-0.00005,0z"
          />
          <path
            stroke="null"
            id="svg_23"
            d="m16.08272,45.44329c-2.54129,0 -4.76015,2.07035 -4.76015,4.77478s2.064,4.77478 4.76015,4.77478s4.76015,-2.07035 4.76015,-4.77478s-2.064,-4.77478 -4.76015,-4.77478zm0,6.20467c-0.79336,0 -1.58672,-0.63415 -1.58672,-1.59159c0,-0.95744 0.63221,-1.59159 1.58672,-1.59159c0.95451,0 1.58672,0.63415 1.58672,1.59159c0,0.95744 -0.6384,1.59159 -1.58672,1.59159z"
          />
          <path
            stroke="null"
            id="svg_24"
            d="m34.62669,40.51253c2.52891,0.28599 2.10732,4.07846 -0.41528,3.79866c-2.52256,-0.27977 -2.10732,-4.07846 0.41528,-3.79866"
          />
          <path
            stroke="null"
            id="svg_25"
            d="m16.36674,21.10304c2.52891,0.27977 2.10732,4.07225 -0.41528,3.79245s-2.10732,-4.07846 0.41528,-3.79245"
          />
          <path
            stroke="null"
            id="svg_26"
            d="m40.36109,23.79442l-0.79336,-0.7958l0.79336,-0.7958c0.63221,-0.63415 0.63221,-1.59159 0,-2.22569c-0.63221,-0.63415 -1.58672,-0.63415 -2.21887,0l-0.79336,0.7958l-0.79336,-0.7958c-0.63221,-0.63415 -1.58672,-0.63415 -2.21887,0c-0.63221,0.63415 -0.63221,1.59159 0,2.22569l0.79336,0.7958l-0.79336,0.7958c-0.63221,0.63415 -0.63221,1.59159 0,2.22569c0.31611,0.31708 0.79336,0.47872 1.10946,0.47872c0.31611,0 0.79336,-0.16164 1.10946,-0.47872l0.79336,-0.7958l0.79336,0.7958c0.31611,0.31708 0.79336,0.47872 1.10946,0.47872c0.31611,0 0.79336,-0.16164 1.10946,-0.47872c0.47106,-0.63415 0.47106,-1.58537 0,-2.22569l-0.00013,0z"
          />
          <path
            stroke="null"
            id="svg_27"
            d="m55.58882,3.89981c0,2.5491 -3.80558,2.5491 -3.80558,0c0,-2.54289 3.80558,-2.54289 3.80558,0"
          />
          <path
            stroke="null"
            id="svg_28"
            d="m70.03113,11.22401c0,2.54289 -3.81177,2.54289 -3.81177,0c0,-2.5491 3.81177,-2.5491 3.81177,0"
          />
        </g>
        <g stroke="null" id="svg_29">
          <path
            stroke="null"
            id="svg_30"
            d="m102.96286,45.81018c-11.09133,-11.96886 -25.84631,-18.56018 -41.55042,-18.56018c-1.40228,0 -2.82081,0.05416 -4.21866,0.16088c-0.97717,0.07487 -1.79197,0.83626 -2.00453,1.86844l-17.13739,83.72787c-0.16237,0.79644 0.062,1.62633 0.59634,2.20454c0.41921,0.45238 0.98455,0.69927 1.56613,0.69927c0.15794,0 0.31884,-0.01911 0.47825,-0.05575l77.58913,-18.49168c0.95651,-0.22937 1.6606,-1.10705 1.72998,-2.16312c1.2163,-18.38337 -4.99804,-36.38604 -17.04882,-49.39027zm-46.16025,-2.25232c13.25823,-1.50845 26.17991,3.37849 35.64902,13.59519c9.46763,10.21829 14.00218,24.15754 12.59842,38.46952l-61.92487,14.75958l13.67744,-66.82428zm58.88561,49.52885l-6.08887,1.45111c1.07016,-15.19443 -3.94116,-29.89348 -14.01546,-40.76485c-10.07431,-10.87296 -23.6927,-16.27918 -37.77606,-15.12435l1.34472,-6.57061c15.35132,-0.6929 29.89522,5.47152 40.67953,17.10907c10.75331,11.60728 16.48053,27.5201 15.85614,43.89963z"
          />
          <circle stroke="null" id="svg_31" r="12.56808" cy="69.23818" cx="69.6623" />
          <circle stroke="null" id="svg_32" r="6.97026" cy="84.78782" cx="92.06342" />
          <circle stroke="null" id="svg_33" r="4.07735" cy="94.26224" cx="64.98753" />
        </g>
      </g>
    </svg>
  </div>
</div>
