interface IProductBasics {
  business_id: string
  id: string
  delivery_price: number
  not_delivery_price: number
  price: number
  description: string
  imageUrl: string
  in_inventory: boolean
  name: string
  type_id: string
  type_name: ProductType
  count: number
  original_id?: string
  shape?: ProductShape
  isCoupon?: boolean
  picture?: string
  icon?: string
  kitchen_id: string
}

export interface IProductDeal extends IProductBasics {
  items: IProductItem[]
  categories?: never
}

export interface IProductOther extends IProductBasics {
  items?: never
  categories: IProductCategory[]
}

export interface IProductItem {
  deal_id: string
  id: string
  products: IProductOther[]
  type_id: string
  type_name: ProductType
  selected: boolean
  doneAdding?: boolean
  icon?: string
}

export interface IProductCategory {
  category_has_fixed_price: boolean
  fixed_price: number
  id: string
  is_mandatory: boolean
  is_multiple_selection: boolean
  is_topping_divided: boolean
  list_order: number
  name: string
  product_id: string
  products_count: number
  products_fixed_price: number
  products_limit: number
  products: IToppingProduct[]
  multipleProducts?: IToppingProduct[]
  price?: number
  layers?: IToppingLayer[]
}

export interface IToppingProduct {
  category_id: string
  id: string
  in_inventory: boolean
  name: string
  original_id: string
  picture: string
  price: number
  originalPrice: number
  type_name: string
  selected?: boolean
  count?: number
  location?: ToppingLocation
}

export type IProduct = IProductDeal | IProductOther

export interface IProductsResponse {
  products: IProduct[]
  status: true
}

export enum ProductShape {
  Circle = 'circle',
  Rectangle = 'rectangle',
  Sliced = 'slice',
}

export enum ProductType {
  pizza = 'pizza',
  drink = 'drink',
  additionalOffer = 'additionalOffer',
  topping = 'topping',
  deal = 'deal',
  additionalCharge = 'additionalCharge',
  sushi = 'sushi',
  burger = 'burger',
  shawarma = 'shawarma',
  sandwich = 'sandwich',
  salad = 'salad',
  flower = 'flower',
  pasta = 'pasta',
  dessert = 'dessert',
  appetizer = 'appetizer',
  general = 'general',
}

export declare type ToppingType = 'full' | 'half' | 'quarter'
export declare type ToppingLocation =
  | 'full'
  | 'rightHalfPizza'
  | 'leftHalfPizza'
  | 'tr'
  | 'tl'
  | 'br'
  | 'bl'

export const productNameTranslate: {
  [key in keyof typeof ProductType]: string
} = {
  pizza: 'פיצה',
  drink: 'שתיה',
  additionalOffer: 'מוצרים נוספים',
  topping: 'תוספות',
  deal: 'מבצע',
  additionalCharge: 'חיובים נוספים',
  sushi: 'סושי',
  burger: 'המבורגר',
  shawarma: 'שווארמה',
  sandwich: 'סנוויץ',
  salad: 'סלט',
  flower: 'פרחים',
  pasta: 'פסטה',
  dessert: 'קינוח',
  appetizer: 'ראשונות',
  general: 'כללי',
}

export interface IRemoveCartTopping {
  id: string
  toppingIndex: number
  categoryIndex: number
  productIndex: number
  itemIndex?: number
  dealIndex?: number
}

export interface IToppingLayer {
  price: number
  isDeal: boolean
}
