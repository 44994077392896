<div class="page-container suggestions">
  <bi-card cardTitle="הצעות לשיפור">
    <div class="suggest-container">
      <form [formGroup]="suggestForm" (ngSubmit)="onAddSuggestion()">
        <div class="form-control">
          <input type="text" formControlName="name" placeholder="שם פרטי*" pattern="^(?!\s*$).+" />
        </div>
        <div class="form-control">
          <input
            type="phone"
            maxlength="10"
            formControlName="phone"
            placeholder="טלפון*"
            pattern="^(?!\s*$).+"
          />
        </div>
        <div class="form-control">
          <input type="email" formControlName="email" placeholder="email*" pattern="^(?!\s*$).+" />
        </div>
        <div class="form-control">
          <textarea
            name="message"
            formControlName="message"
            placeholder="הצעות לשיפור*"
            pattern="^(?!\s*$).+"
          ></textarea>
          <div class="helper">שדה חובה</div>
        </div>
        <div class="action">
          <button
            type="submit"
            class="bi-btn bi-btn-ghost full"
            [disabled]="suggestForm.invalid || isSending"
          >
            שלח
          </button>
        </div>
      </form>

      <div class="result">{{result}}</div>
      <div
        class="button popup-close"
        (click)="!this.deliveryOption ? this.router.navigate([SiteRoutes.Home]) : this.goToFirstPage()"
      >
        x
      </div>
    </div>
  </bi-card>
</div>
